/* ------------------------------------------------------------------------ * * Helpers
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Colors
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Fonts                                                                    *
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Widths                                                                   *
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Break Points                                                             *
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Layers                                                                   *
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Functions
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Mixins
\* ------------------------------------------------------------------------ */
._dark {
  color: #000000 !important; }

._light {
  color: #FFFFFF !important; }

._big {
  font-size: 1.25em !important; }

._small {
  font-size: 0.75em !important; }

._bold {
  font-weight: 700 !important; }

._italic {
  font-style: italic !important; }

._textcenter {
  text-align: center !important; }

._textleft {
  text-align: left !important; }

._textright {
  text-align: right !important; }

._nowrap {
  white-space: nowrap !important; }

._center {
  margin-left: auto !important;
  margin-right: auto !important; }

._left {
  float: left !important; }

._right {
  float: right !important; }

._nomargin {
  margin: 0 !important; }

._nopadding {
  padding: 0 !important; }

._block {
  display: block !important; }

._flex {
  display: -ms-flexbox !important;
  -js-display: flex !important;
  display: flex !important; }

._visuallyhidden {
  background: #f1f1f1 !important;
  border-radius: 0 !important;
  box-shadow: 0em 0em 0.14286em 0.14286em rgba(0, 0, 0, 0.6) !important;
  color: #B7232F !important;
  display: block !important;
  font-family: "San Francisco", "Open Sans", "Helvetica", "Arial", sans-serif !important;
  font-size: 14px !important;
  font-size: 0.875rem !important;
  font-weight: 700 !important;
  height: auto !important;
  left: -999em !important;
  letter-spacing: normal !important;
  line-height: normal !important;
  outline: none !important;
  padding: 1.07143em 1.64286em 1em !important;
  position: absolute;
  text-decoration: none !important;
  text-shadow: none !important;
  text-transform: none !important;
  top: -999em !important;
  white-space: nowrap;
  width: auto !important; }

._visuallyhidden:focus {
  left: 0.42857em !important;
  top: 0.42857em !important;
  z-index: 100000 !important; }

._primary {
  color: #121131 !important; }

._accent {
  color: #B7232F !important; }

._warning {
  color: #9F0000 !important; }

._sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0 !important; }

@media screen and (min-width: 601px) {
  @supports ((position: -webkit-sticky) or (position: sticky)) {
    .admin-bar ._sticky {
      top: 46px !important; } } }

@media screen and (min-width: 783px) {
  @supports ((position: -webkit-sticky) or (position: sticky)) {
    .admin-bar ._sticky {
      top: 32px !important; } } }

/* visibility classes */
@media (pointer: fine) {
  ._touch {
    display: none !important; } }

@supports not (pointer: fine) {
  ._touch {
    display: none !important; } }

@media (pointer: course) {
  ._mouse {
    display: none !important; } }

@media print {
  ._noprint {
    display: none !important; } }

@media screen and (max-width: 47.9375em) {
  ._tablet:not(._phone),
  ._notebook:not(._phone),
  ._desktop:not(._phone) {
    display: none !important; } }

@media screen and (min-width: 48em) and (max-width: 63.9375em) {
  ._phone:not(._tablet),
  ._notebook:not(._tablet),
  ._desktop:not(._tablet) {
    display: none !important; } }

@media screen and (min-width: 64em) and (max-width: 79.9375em) {
  ._phone:not(._notebook),
  ._tablet:not(._notebook),
  ._desktop:not(._notebook) {
    display: none !important; } }

@media screen and (min-width: 80em) {
  ._phone:not(._desktop),
  ._tablet:not(._desktop),
  ._notebook:not(._desktop) {
    display: none !important; } }

/* ------------------------------------------------------------------------ * * Module
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Layout
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Module
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Layout
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Module
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Swiper (http://idangero.us/swiper/)
\* ------------------------------------------------------------------------ */
/* stylelint-disable selector-class-pattern */
.swiper-slide-inner {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  -js-display: flex;
  display: flex; }

.swiper-picture {
  height: auto;
  -ms-flex-order: 2;
      order: 2;
  padding-bottom: 0;
  width: 50%; }

.swiper-image {
  float: right;
  margin-right: -6.25em;
  /* stylelint-disable property-no-vendor-prefix */
  -webkit-mask-image: none;
  /* stylelint-enable property-no-vendor-prefix */
  mask-image: none;
  position: static;
  right: auto;
  top: auto;
  width: auto; }

.swiper-caption {
  -ms-flex-order: 1;
      order: 1;
  position: relative;
  width: 50%;
  z-index: 1; }

.swiper-user-content {
  width: 75%; }

/* ------------------------------------------------------------------------ * * Layout
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Header
\* ------------------------------------------------------------------------ */
@media screen and (max-width: 80em) {
  .header-block {
    font-size: 1.25vw; } }

.header_logo {
  max-width: 100%;
  padding-right: 80px;
  padding-right: 5rem;
  width: 23.75em; }

/* ------------------------------------------------------------------------ * * CTA
\* ------------------------------------------------------------------------ */
.cta_title {
  font-size: 1.625em; }

/* ------------------------------------------------------------------------ * * Module
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Layout
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Module
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Layout
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Module
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Layout
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Module
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Layout
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Module
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Layout
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Module
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Layout
\* ------------------------------------------------------------------------ */
